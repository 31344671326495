// @vue/component
export default {
  name: 'modal-component',
  props: {
    isSuccess: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    closeModal(page, url) {
      const route = this.$route.name;

      if (route === page) {
        this.$emit('closeModal');
      } else {
        this.$router.push({ path: url });
      }
    },
  },
};
