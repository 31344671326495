import 'normalize.css';
import Vue from 'vue';
import VeeValidate, { Validator } from 'vee-validate';
import App from './App.vue';
import router from './router';

Vue.config.productionTip = false;
Vue.use(VeeValidate);

const dict = {
  custom: {
    email: {
      required: 'Please insert a valid email',
    },
    name: {
      required: 'Please insert your name',
    },
    businessUrl: {
      required: 'Please insert a valid URL',
    },
    additionalInfo: {
      required: 'Please tell us about your goals',
    },
  },
};

Validator.localize('en', dict);

new Vue({
  router,
  render: (h) => h(App),
}).$mount('#app');
