var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('header',{staticClass:"header absolute inset-x-0 top-0 flex justify-between items-center mx-auto z-10 md:px-11 xs:px-5"},[_c('router-link',{staticClass:"logo",attrs:{"to":"/"}},[_c('img',{attrs:{"src":require("../../assets/images/kk_logo_horiz.png"),"alt":"kk elite consulting logo"}})]),_c('nav',{staticClass:"nav-wrapper"},[_c('ul',{class:['nav-list', 'flex', 'justify-between', { 'locked': _vm.lock }]},_vm._l((_vm.navigationItems),function(item,index){return _c('li',{key:item.name},[_c('router-link',{class:[
                        'text-white',
                        'uppercase',
                        'text-sm',
                        'font-semibold',
                        '2xl:text-xl',
                        'link',
                        'tracking-widest',
                        _vm.pageName,
                        { 'pr-14' : index + 1 !== _vm.navigationItems.length } ],attrs:{"to":item.url}},[_vm._v(" "+_vm._s(item.name)+" ")])],1)}),0)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }