import Slick from 'vue-slick';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import generalIcon from '../../assets/images/icons/general_icon.png';
import dataIcon from '../../assets/images/icons/data_icon.png';
import rdIcon from '../../assets/images/icons/r&d_icon.png';
import entrepreneurIcon from '../../assets/images/icons/entrepreneur_icon.png';
import marketingIcon from '../../assets/images/icons/marketing_icon.png';
import brandingIcon from '../../assets/images/icons/branding_icon.png';
import carIcon from '../../assets/images/icons/car_icon.png';
import industrialIcon from '../../assets/images/icons/industrial_icon.png';
import pharmIcon from '../../assets/images/icons/pharm_icon.png';
import cosmeticsIcon from '../../assets/images/icons/cosmetics_icon.png';
import itIcon from '../../assets/images/icons/it_icon.png';
import SelectComponent from '../../components/SelectComponent/SelectComponent.vue';
import ModalComponent from '../../components/ModalComponent/ModalComponent.vue';
import 'slick-carousel/slick/slick.scss';
import 'slick-carousel/slick/slick-theme.scss';
import research from '../../assets/images/research_pic.png';
import strategy from '../../assets/images/strategy_pic.png';
import brand from '../../assets/images/brand_pic.png';
import logoSample from '../../assets/images/logo-sample.png';
import BaseInput from '../../components/BaseInput/BaseInput.vue';

// @vue/component
export default {
  name: 'Homepage',
  components: {
    [SelectComponent.name]: SelectComponent,
    Slick,
    ValidationProvider,
    ValidationObserver,
    BaseInput,
    ModalComponent,
  },
  data() {
    return {
      services: [
        {
          icon: {
            src: generalIcon,
          },
          name: 'General Business Consultation',
          description: 'Using technical expertise, inovations, industry economics, and competitive environments to help businesses.',
          url: '/services#hands',
          alias: 'hands',
          isVisible: true,
        },
        {
          icon: {
            src: dataIcon,
          },
          name: 'Data Analysis',
          description: 'Extract data from the various data sources, transform the data as needed, and consolidate into a data warehouse for improved analytics insights.',
          url: '/services#data',
          alias: 'data',
          isVisible: true,
        },
        {
          icon: {
            src: rdIcon,
          },
          name: 'R&D/Tech Consulting',
          description: 'Providing strategic advisory and technology to align business needs.',
          url: '/services#settings',
          alias: 'settings',
          isVisible: true,
        },
        {
          icon: {
            src: entrepreneurIcon,
          },
          name: 'Entrepreneur Consultation',
          description: 'Recognize new business opportunities for the organization to secure new projects from new or existing clients.',
          url: '/services#money',
          alias: 'money',
          isVisible: true,
        },
        {
          icon: {
            src: marketingIcon,
          },
          name: 'Marketing Consultation',
          description: 'Proposing a wide range of marketing options such as: Radio, Social Media, TV & Telephone marketing.',
          url: '/services#loud',
          alias: 'loud',
          isVisible: true,
        },
        {
          icon: {
            src: brandingIcon,
          },
          name: 'Branding Solutions',
          description: 'Rebuilding brands to boost business by revising and recreating brand clarity.',
          url: '/services#idea',
          alias: 'idea',
          isVisible: true,
        },
      ],
      experience: [
        'Market-driven, customer-centric approach for improved strategic decision-making.',
        'Working with us has many benefits for you, your business and your stakeholders. <br/> We are known for our creative, yet professional approach, which is based on <br/> establishing a unique relationship with each of our clients.',
        'Through a unique interdisciplinary approach, we can bring a range of innovative <br/> possibilities to businesses looking for a change.',
        'We use creative approaches to consultancy, which combine the latest thinking with <br/> both grounded theoretical knowledge and practical experience.',
        'Our adaptable and quality service provides customers with measured results in a <br/> time frame which is individually tailored to each client.',
      ],
      industries: [
        {
          icon: {
            width: '67px',
            height: '36px',
            src: carIcon,
          },
          name: 'automobile',
          alias: 'auto',
        },
        {
          icon: {
            width: '36px',
            height: '36px',
            src: industrialIcon,
          },
          name: 'industrial equipment',
          alias: 'equipment',
        },
        {
          icon: {
            width: '42px',
            height: '42px',
            src: pharmIcon,
          },
          name: 'pharmaceutical',
          alias: 'pharmaceutical',
        },
        {
          icon: {
            width: '38px',
            height: '38px',
            src: cosmeticsIcon,
          },
          name: 'cosmetics',
          alias: 'cosmetics',
        },
        {
          icon: {
            width: '45px',
            height: '45px',
            src: itIcon,
          },
          name: 'it solutions',
          alias: 'it',
        },
      ],
      options: [
        'Select an industry',
        'automobile',
        'industrial equipment',
        'pharmaceutical',
        'cosmetics',
        'it solutions',
        'others',
      ],
      slidesForFirstSlider: [
        {
          image: research,
          title: 'Developed integrated marketing communication Strategy',
          text: [
            'Using promotional marketing function to consistently and persuasively move the targeted audience through the decision marketing process by using various marketing channels.',
            'Creating content marketing, email marketing, social media marketing.',
            'Consistently collaborated with the business’ media team to ensure that campaigns are running seamlessly.',
          ],
          url: '/services#research',
        },
        {
          image: strategy,
          title: 'Customer experience strategy',
          text: [
            'Stimulates the intellect and stirs the soul of your target consumer (through in depth consumer analysis where we identify gaps in the market and opportunities for success).',
          ],
          url: '/services#strategy',
        },
        {
          image: brand,
          title: 'Brand strategy',
          text: [
            'Repositioning an existing brand in a crowded market (drive sales).',
            'Audience segmentation (increase conversion rates).',
            'Organizing a portfolio of brands or products.',
          ],
          url: '/services#brand',
        },
      ],
      slickFirstSliderOptions: {
        dots: true,
        arrows: true,
        swipe: true,
        slidesToShow: 1,
        autoplay: true,
      },
      slidesForSecondSlider: [
        {
          reviews: [
            '“KK Elite has been nothing but a pleasure to work with, from there customer service to marketing and branding ideas they have all done an amazing job to get my business going.',
            'I can finally say I see a successful future for my business.”',
          ],
          reviewer: {
            name: '— Dr. Lin, Owner – NP-Bio Cosmetics',
            company: {
              logo: logoSample,
              isEnabled: false,
              name: 'China minsheng bank',
            },
          },
        },
        {
          reviews: [
            '“KK Elite SIMPLY THE BEST!',
            'Thank you so much for your great service.”',
          ],
          reviewer: {
            name: '— Markus Antonio, Managing Director – First Race Auto',
            company: {
              logo: logoSample,
              isEnabled: false,
              name: 'China minsheng bank',
            },
          },
        },
        {
          reviews: [
            '“As a small business owner it can be overwhelming to know where to start from. However, I gave KK Elite a call, and left my branding to them.',
            'It was the best decision I made for my business. My small business has turned into a multimillion dollar business.”',
          ],
          reviewer: {
            name: '— Nora Beddie, CEO – Weddings & CO.',
            company: {
              logo: logoSample,
              isEnabled: false,
              name: 'China minsheng bank',
            },
          },
        },
      ],
      slickSecondSliderOptions: {
        dots: true,
        arrows: true,
        swipe: true,
        slidesToShow: 1,
        autoplay: true,
      },
      slickThirdSliderOptions: {
        dots: false,
        arrows: false,
        slidesToShow: 5,
        responsive: [
          {
            breakpoint: 960,
            settings: {
              slidesToShow: 3,
            },
          },
        ],
      },
      customerInfo: {
        name: '',
        email: '',
        businessUrl: '',
        additionalInfo: '',
        topic: '',
      },
      selectError: null,
      isSeenAllCategories: false,
      isMessageShown: false,
      isSuccess: true,
    };
  },
  methods: {
    async sendEmail() {
      this.resetError();

      if (this.$refs['select-topic'].selected === 'Select an industry') {
        this.selectError = 'Please select an industry';
      }

      const isValid = await this.$refs.form.validate();

      if (isValid && this.selectError === null) {
        const data = {
          email: this.customerInfo.email,
          name: this.customerInfo.name,
          businessUrl: this.customerInfo.businessUrl,
          additionalInfo: this.customerInfo.additionalInfo,
          topic: this.$refs['select-topic'].selected ?? '',
        };

        try {
          const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data),
          };

          await fetch('/send-email', requestOptions);

          this.isMessageShown = true;
          this.isSuccess = true;

          this.$el.classList.add('modalShown');
        } catch (e) {
          console.log('ERROR', e);
          this.isMessageShown = true;
          this.isSuccess = false;
        }
      }
    },

    closeModal() {
      this.isMessageShown = false;
    },

    resetError() {
      this.selectError = null;
    },

    scrollToProduct() {
      if (this.$route.hash !== '') {
        /* eslint-disable */
        const elem = globalThis.document.querySelector(this.$route.hash);

        if (elem !== null) {
          elem.scrollIntoView({ block: 'center', behavior: 'smooth' });
        }
      }
    },

    openCategories() {
      this.isSeenAllCategories = !this.isSeenAllCategories;

      console.log(this.$el);
    },
  },
};
