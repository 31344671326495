// @vue/component
export default {
  name: 'FooterComponent',
  data() {
    return {
      navigationItems: [
        {
          name: 'About us',
          url: '/about-us',
        },
        {
          name: 'Services',
          url: '/services',
        },
        {
          name: 'Contact us',
          url: '/contact-us',
        },
      ],
    };
  },
};
