import Vue from 'vue';
import VueRouter from 'vue-router';
import Homepage from '../views/Homepage/Homepage.vue';
import AboutUs from '../views/AboutUs/AboutUs.vue';
import Services from '../views/Services/Services.vue';
import ContactUs from '../views/ContactUs/ContactUs.vue';
import TopHeaderComponent from '../components/TopHeaderComponent/TopHeaderComponent.vue';
import FooterComponent from '../components/FooterComponent/FooterComponent.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Homepage',
    components: {
      default: Homepage,
      header: TopHeaderComponent,
      footer: FooterComponent,
    },
  },
  {
    path: '/about-us',
    name: 'AboutUs',
    components: {
      default: AboutUs,
      header: TopHeaderComponent,
      footer: FooterComponent,
    },
  },
  {
    path: '/services',
    name: 'Services',
    components: {
      default: Services,
      header: TopHeaderComponent,
      footer: FooterComponent,
    },
  },
  {
    path: '/contact-us',
    name: 'contactUs',
    components: {
      default: ContactUs,
      header: TopHeaderComponent,
      footer: FooterComponent,
    },
  },
];

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }

    if (to.hash) {
      return { selector: to.hash };
    }

    return { x: 0, y: 0 };
  },
});

export default router;
