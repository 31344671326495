// @vue/component
export default {
  name: 'base-input',
  $_veeValidate: {
    value() {
      return this.value;
    },
  },
  data() {
    return {
      innerValue: '',
    };
  },
  props: {
    value: {
      type: String,
      required: true,
    },
    customErrors: {
      type: Array,
      default: () => [],
    },
    id: {
      type: String,
      required: true,
    },
    labelText: {
      type: String,
      default: '',
    },
    isRequired: {
      type: Boolean,
      default: false,
    },
    isInverted: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    updateValue(e) {
      this.$emit('input', e.target.value);
    },
  },
};
