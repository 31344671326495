import { ValidationProvider, ValidationObserver } from 'vee-validate';
import SelectComponent from '../../components/SelectComponent/SelectComponent.vue';
import BaseInput from '../../components/BaseInput/BaseInput.vue';
import ModalComponent from '../../components/ModalComponent/ModalComponent.vue';

// @vue/component
export default {
  name: 'ContactUs',
  components: {
    [SelectComponent.name]: SelectComponent,
    BaseInput,
    ValidationProvider,
    ValidationObserver,
    ModalComponent,
  },
  data() {
    return {
      options: [
        'Select an industry',
        'automobile',
        'industrial equipment',
        'pharmaceutical',
        'cosmetics',
        'it solutions',
        'others',
      ],
      customerInfo: {
        name: '',
        email: '',
        businessUrl: '',
        additionalInfo: '',
        topic: '',
      },
      selectError: null,
      isMessageShown: false,
      isSuccess: true,
    };
  },
  methods: {
    async sendEmail() {
      this.resetError();

      if (this.$refs['select-topic'].selected === 'Select an industry') {
        this.selectError = 'Please select an industry';
      }

      const isValid = await this.$refs.form.validate();

      if (isValid && this.selectError === null) {
        const data = {
          email: this.customerInfo.email,
          name: this.customerInfo.name,
          businessUrl: this.customerInfo.businessUrl,
          additionalInfo: this.customerInfo.additionalInfo,
          topic: this.$refs['select-topic'].selected ?? '',
        };

        try {
          const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data),
          };

          await fetch('/send-email', requestOptions);

          this.isMessageShown = true;
          this.isSuccess = true;
        } catch (e) {
          console.log('ERROR', e);
          this.isMessageShown = true;
          this.isSuccess = false;
        }
      }
    },

    closeModal() {
      this.isMessageShown = false;
    },

    resetError() {
      this.selectError = null;
    },
  },
};
