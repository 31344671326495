import Slick from 'vue-slick';
import 'slick-carousel/slick/slick.scss';
import 'slick-carousel/slick/slick-theme.scss';
import logoSample from '../../assets/images/logo-sample.png';

// @vue/component
export default {
  name: 'AboutUs',
  components: {
    Slick,
  },
  data() {
    return {
      slidesForSecondSlider: [
        {
          reviews: [
            '“KK Elite has been nothing but a pleasure to work with, from there customer service to marketing and branding ideas they have all done an amazing job to get my business going.',
            'I can finally say I see a successful future for my business.”',
          ],
          reviewer: {
            name: '— Dr. Lin, Owner – NP-Bio Cosmetics',
            company: {
              logo: logoSample,
              isEnabled: false,
              name: 'China minsheng bank',
            },
          },
        },
        {
          reviews: [
            '“KK Elite SIMPLY THE BEST!',
            'Thank you so much for your great service.”',
          ],
          reviewer: {
            name: '— Markus Antonio, Managing Director – First Race Auto',
            company: {
              logo: logoSample,
              isEnabled: false,
              name: 'China minsheng bank',
            },
          },
        },
        {
          reviews: [
            '“As a small business owner it can be overwhelming to know where to start from. However, I gave KK Elite a call, and left my branding to them.',
            'It was the best decision I made for my business. My small business has turned into a multimillion dollar business.”',
          ],
          reviewer: {
            name: '— Nora Beddie, CEO – Weddings & CO.',
            company: {
              logo: logoSample,
              isEnabled: false,
              name: 'China minsheng bank',
            },
          },
        },
      ],
      slickSecondSliderOptions: {
        dots: true,
        arrows: true,
        swipe: true,
        slidesToShow: 1,
        autoplay: true,
      },
      text: [
        'We use market-driven, customer-centric approach for improved strategic decision-making.',
        'Working with us has many benefits for you, your business and your stakeholders. We are known for our creative, yet professional approach, which is based on establishing a unique relationship with each of our clients.',
        'Through a unique interdisciplinary approach, we can bring a range of innovative possibilities to businesses looking for a change.',
        'We use creative approaches to consultancy, which combine the latest thinking with both grounded theoretical knowledge and practical experience.',
        'Our adaptable and quality service provides customers with measured results in a time frame which is individually tailored to each client.',
      ],
    };
  },
};
