// @vue/component
export default {
  name: 'TopHeaderComponent',
  data() {
    return {
      navigationItems: [
        {
          name: 'About us',
          url: '/about-us',
        },
        {
          name: 'Services',
          url: '/services',
        },
        {
          name: 'Contact us',
          url: '/contact-us',
        },
      ],
      scrollY: 0,
      scrollPosition: 0,
      lock: false,
    };
  },
  computed: {
    pageName() {
      return this.$route.name ?? '';
    },
  },
  created() {
    window.addEventListener('scroll', () => {
      this.scrollY = window.scrollY;

      this.lock = this.scrollY <= this.scrollPosition;
      this.scrollPosition = this.scrollY;

      if (this.scrollY === 0) {
        this.lock = false;
      }
    });
  },
};
