// @vue/component
export default {
  name: 'selectComponent',
  props: {
    options: {
      type: Array,
      required: true,
    },
    tabindex: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  data() {
    return {
      selected: this.options.length > 0 ? this.options[0] : null,
      isOpen: false,
    };
  },
  mounted() {
    this.$emit('input', this.selected);
  },
  computed: {
    preparedOptions() {
      const { options } = this;
      options.splice(0, 1);

      return options;
    },
  },
  methods: {
    toggleSelector() {
      this.isOpen = !this.isOpen;
    },
    chooseOption(option) {
      this.selected = option;
      this.isOpen = false;
      this.$emit('input', option);
      this.$emit('changed');
    },
  },
};
