import generalIcon from '../../assets/images/icons/general_icon.png';
import dataIcon from '../../assets/images/icons/data_icon.png';
import rdIcon from '../../assets/images/icons/r&d_icon.png';
import entrepreneurIcon from '../../assets/images/icons/entrepreneur_icon.png';
import marketingIcon from '../../assets/images/icons/marketing_icon.png';
import brandingIcon from '../../assets/images/icons/branding_icon.png';
import research from '../../assets/images/research_pic.png';
import strategy from '../../assets/images/strategy_pic.png';
import brand from '../../assets/images/brand_pic.png';

// @vue/component
export default {
  name: 'Services',
  data() {
    return {
      services: [
        {
          icon: generalIcon,
          title: 'General Business Consultation',
          text: [
            'Conducted industry analysis to understand the industry more in depth, using technical expertise and industry economics, customer needs, and competitive environment to help businesses',
            'Innovation Analysis: Identified the needs (timelines, budget, constraints) of the company to develop the best innovative solution',
            'Suggest ways to retain high-value customers more profitably and effectively',
            'Consistently generated more and quality ideas by using depth of experience and range of expertise, and rigorous creative process allowing creativity and disciple do go together',
          ],
          alias: 'hands',
        },
        {
          icon: rdIcon,
          title: 'R&D/Tech Consulting',
          text: [
            'Worked with the business’ preferred technologies and platforms to fully setup, implement, and maintain a system to sustain its competitive advantage',
            'Provided strategic advisory and technology to align business needs that drive target business outcomes',
          ],
          alias: 'settings',
        },
        {
          icon: dataIcon,
          title: 'Data Analysis',
          text: [
            'Created visualizations and dashboards that effectively communicate results, showing areas of success and opportunities for improvement',
            'Identified and captured the most value and meaningful insights from data and then turn them into competitive advantages',
            'Selected amongst an array of methods that optimizes various business intelligence tasks by leveraging existing data',
            'Developed strategic models to forecast customer behavior using predictive analytics',
            'Created meaningful insights, visualizations and predictive tools that help inform business decisions, by collecting, collating and carrying out complex data analysis',
            'Extract data from the various data sources, transform the data as needed, and consolidate into a data warehouse for improved analytics insights',
          ],
          alias: 'data',
        },
        {
          icon: entrepreneurIcon,
          title: 'Entrepreneur Consultation',
          text: [
            'Recognize new business opportunities for the organization to secure new projects from new or existing clients',
          ],
          alias: 'money',
        },
        {
          icon: marketingIcon,
          title: 'Marketing Consultation',
          text: [
            'Measure the effectiveness of social media spending',
            'Proposed a wide range of marketing data/process to businesses based on their strategy to help grow and thrive',
            'Social media marketing (to connect with their target audience and effectively engage with them at scale)',
            'SEO (help to create long term investment allowing businesses to increase their reach both among referrals and end users)',
            'Offer radio, digital, flyer distribution, TV advertising and telephone marketing programs.',
            'Public Relations',
          ],
          alias: 'loud',
        },
        {
          icon: brandingIcon,
          title: 'Branding Solutions',
          text: [
            'Helped to rebuild brand and boost business by revising and recreating brand clarity, relevance, excitement and momentum',
          ],
          alias: 'idea',
        },
      ],
      solutions: [
        {
          image: brand,
          title: 'Brand strategy',
          text: [
            'Repositioning an existing brand in a crowded market (drive sales).',
            'Audience segmentation (increase conversion rates).',
            'Organizing a portfolio of brands or products.',
          ],
          isInverted: false,
          id: 'brand',
        },
        {
          image: research,
          title: 'Developed integrated marketing communication Strategy',
          text: [
            'Using promotional marketing function to consistently and persuasively move the targeted audience through the decision marketing process by using various marketing channels.',
            'Creating content marketing, email marketing, social media marketing.',
            'Consistently collaborated with the business’ media team to ensure that campaigns are running seamlessly.',
          ],
          isInverted: true,
          id: 'research',
        },
        {
          image: strategy,
          title: 'Customer experience strategy',
          text: [
            'Stimulates the intellect and stirs the soul of your target consumer (through in depth consumer analysis where we identify gaps in the market and opportunities for success).',
          ],
          isInverted: false,
          id: 'strategy',
        },
      ],
    };
  },
};
